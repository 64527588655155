* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:active,
:focus,
a:active,
a:focus {
  outline: 0;
}

aside,
footer,
header,
nav {
  display: block;
}

body,
html {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

button,
input,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

a {
  color: inherit;
}

body {
  font-family: Montserrat;
  color: #071731;
}

.jc-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ride {
  max-width: 800px;
&__footer {
  display: flex;
  justify-content: center;
}
&__spinner {
  width: 50px;
  height:50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
}
.ride-block {
 width: 100%;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.aside {
  width: 320px;
  -webkit-box-flex: 320px;
  -ms-flex: 320px 1;
  flex: 320px 1;
  background: url(../img/bg.png) 50% / cover no-repeat;
  min-height: 100vh;
  height: auto;
}

.aside__inner {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.menu {
  color: #fff;
}

.menu,
.menu__list {
  width: 100%;
}

.menu__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  font-size: 24px;
  cursor: pointer;
}

.menu__link {
  position: relative;
}

.menu__link:after {
  content: "";
  width: 0;
  height: 1px;
  background: #fff;
  display: block;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  pointer-events: none;
  position: absolute;
  bottom: 0;
}

.menu__link:hover:after {
  width: 100%;
}

.menu__logo {
  font-size: 32px;
}

.content {
  width: calc(100% - 320px);
  -webkit-box-flex: calc(100% - 320px);
  -ms-flex: calc(100% - 320px) 0 1;
  flex: calc(100% - 320px) 0 1;
  max-height: 100vh;
  height: auto;
  overflow: auto;
}
@media (max-width: 991px) {
  .content {
    max-height: 100%;
    overflow: visible;
  }
  .header__timer {
    display: none !important;
  }
  .header__name {
    width: 100%;
  }
}
.content__inner {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 100px;
  scrollbar-width: 10px;

}

.register {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.register__title {
  margin-bottom: 6px;
  text-align: center;
  span {
    color: #dc3545;
  }
}

.register__description {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.register__input {
  margin-bottom: 20px;
}

.register__btn {
  margin-top: 5px;
}
.register__btn-demo {
  margin-left: 10px;
}
.register__link {
  font-size: 16px;
  color: #1875ba;
  font-weight: 600;
  margin-top: 10px;
}
.register__footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 27px 0 10px;
}
.register__link:hover {
  color: #071731;
}

@media (max-width: 768px) {
  .register__btn,
  .register__btn-demo {
    margin-top: 15px;
  }
}
.page-block {
  padding: 25px;
  border-radius: 5px;
  border-top: 5px solid #2291e3;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.title {
  font-size: 32px;
  line-height: normal;
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
}

.input {
  position: relative;
  width: 100%;
}

.input__placeholder {
  position: absolute;
  font-size: 15px;
  line-height: 18px;
  top: 12px;
  left: 35px;
  background-color: #fff;
  padding: 3px;
  cursor: text;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.input__placeholder.active {
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.input__input {
  padding: 15px 35px;
  height: 50px;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 700;
  font-size: 17px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  line-height: 21px;
}

.input__input.active,
.input__input:hover {
  border: 1px solid #2291e3;
}

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #2291e3;
  border-radius: 10px;
  min-width: 180px;
  width: auto;
  padding: 15px;
  height: 44px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.register__btn-demo {
  margin-left: 10px;
  background: #dc3545;
}
.btn:focus,
.btn:hover {
  background-color: #1875ba;
}
.register__btn-demo:hover {
  background: #b42b39;
}
.field__error {
  background: #dc3545;
  min-height: fit-content;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 15px;
  padding: 10px 10px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}
.input__select {
  margin-bottom: 10px;
}
.schedule {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__col {
    margin:10px 10px 10px;
    transition: all 0.5s;
  }
  &__title {
    display: block;
  
    width: 180px;
    background: #1a7cc4;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 14px;
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 180px;
  }
  &__item {
    height: 34px;
    width: 180x;
    background: #fff;
    color: #000;
    padding: 10px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    &-disable {
      background: #666;
    }
    &-active {
      background: #1a7cc4;
      font-weight: 600;
      color: #fff;
    }
  }
  &__item:not(.schedule__item-active,.schedule__item-disable):hover {
    background: #e6e6e6;
  }
  &__item:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}
.schedule__item-disable {
  background: #666;
  color: white;
}

.thanks-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  &__icon {
    margin-bottom: 10px;
  }
  &__title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__descr {
    font-size: 20px;
  }
}
.general {
  padding: 0 100px;
}

.general__choose {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  background: #fcfcfc;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.general__choose-text {
  padding: 15px 0 0;
  font-size: 16px;
}
.general__choose__list {
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.general__choose__item {
  margin-right: 15px !important;
}

.general__theme {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 50px;
}

.general__theme__list {
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #1875ba;
  cursor: pointer;
}

.general__theme__list:hover {
  background-color: #e6e6e6;
}

.general__theme__label {
  font-size: 16px;
  font-weight: 700;
}

.general__rules__item {
  margin: 15px 0;
  font-weight: 700;
}

.general__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.general__exit__btn,
.general__start__btn {
  width: 350px;
  margin-top: 30px;
}

.header {
  max-width: 900px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header__text {
  font-size: 18px;
  margin-bottom: 10px;
}

.header__highliht {
  font-weight: 700;
  font-size: 22px;
}

.header__progress,
.header__timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header__progress {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 450px;
  padding: 0 10px;
}

.header__line {
  width: 100%;
  border: 1px solid #2291e3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 10px;
  margin-bottom: 20px;
}

.header__line-inner {
  display: block;
  height: 100%;
  background: #2291e3;
  border-radius: 30px;
}

.header__progress-number {
  font-size: 16px;
  color: #e2e2e2;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.header__number-current {
  font-weight: 700;
  font-size: 18px;
  color: #071731;
}

.time {
  font-size: 32px;
  font-weight: 700;
}

.test {
  max-width: 900px;
  width: 100%;
}

.test__question,
.test__title {
  margin-bottom: 15px;
}

.test__question {
  font-size: 22px;
  line-height: normal;
}

.test__img {
  max-width: 290px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.test__img:hover {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}

.test__img img {
  width: 100%;
  height: auto;
}

.test__radio {
  margin: 5px 0;
}

.test__input:checked ~ label {
  background: #2291e3;
  color: #fff;
}

.test__btn {
  margin-top: 20px;
}

.input__radio {
  display: block;
  padding: 15px;
  border: 1px solid #2291e3;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.input__radio:hover {
  background-color: #e6e6e6;
}

.table__tests {
  width: 100%;
}

.empty__tests {
  width: 100%;
}

.empty__tests__container {
  width: 100%;
  height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.empty__tests__container h2 {
  font-size: 27px;
  font-weight: 700;
}

.footer {
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: calc(100vw - 320px);
  width: 100%;
}

.footer__nav {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow: auto;
  /* flex-wrap: wrap; */
}

.footer__btn {
  width: 50px;
  margin: 3px;
  background: #ccc;
  color: #000;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  height: 44px;
  min-width: auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer__btn:focus,
.footer__btn:hover {
  background-color: #1875ba;
}

.footer__btn--true {
  background: #28a745 !important;
  color: #fff;
}

.footer__btn--false {
  background: #dc3545 !important;
  color: #fff;
}

.result {
  text-align: center;
  min-width: 320px;
}

.result__title {
  margin-bottom: 20px;
}

.result__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  padding: 20px;
  margin-bottom: 15px;
}

.result__item span {
  font-size: 18px;
  font-weight: 700;
}

.result__item--success {
  background: #c9ede2;
  border: 1px solid #c9ede2;
}

.result__item--danger {
  background: #dc3545;
  border: 1px solid #dc3545;
  color: #fff;
}

.red {
  color: #dc3545;
}

.burger {
  width: 50px;
  height: 40px;
  background: 0 0;
  position: relative;
}

.burger.active span {
  background-color: #fff;
}

.burger.active span:first-child {
  -webkit-transform: translateY(18px) rotate(45deg);
  -ms-transform: translateY(18px) rotate(45deg);
  transform: translateY(18px) rotate(45deg);
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:last-child {
  -webkit-transform: translateY(-18px) rotate(-45deg);
  -ms-transform: translateY(-18px) rotate(-45deg);
  transform: translateY(-18px) rotate(-45deg);
}

.burger span {
  width: 100%;
  height: 4px;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.burger span:first-child {
  top: 0;
}

.burger span:nth-child(2) {
  top: calc(50% - 2px);
}

.burger span:last-child {
  bottom: 0;
}

.burger__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  padding: 20px;
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  top: 0;
  width: 45px !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-container {
  padding: 0 55px !important;
}

.swiper-slide {
  opacity: 1 !important;
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.swiper-slide-visible {
  opacity: 1 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
  color: #fff;
}

.d-md-block {
  display: none;
}

@media (max-width: 1500px) {
  .test__inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .number_radio {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 1 !important;
    flex: 0 1 !important;
  }

  .test__radio {
    -webkit-box-flex: calc(100%);
    -ms-flex: calc(100%) 0 1;
    flex: calc(100%) 0 1;
    max-width: calc(100%);
  }

  .page-block {
    padding: 15px;
  }

  .test__btn {
    margin-top: 10px;
  }
}

@media (max-width: 1200px) {
  .header__progress {
    display: none;
  }
}

@media (max-width: 991px) {
  .burger__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #fff;
    font-size: 42px;
    width: 100%;
    background: #2291e3;
    z-index: 101;
  }

  .aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding-top: 60px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translate(-100%);
    -ms-transform: translate(-100%);
    transform: translate(-100%);
    background: #2291e3;
  }

  .aside.active {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .menu__logo {
    display: none;
  }

  .content {
    width: 100%;
    -webkit-box-flex: 100%;
    -ms-flex: 100% 0 1;
    flex: 100% 0 1;
    padding-top: 60px;
  }

  .content__inner {
    height: auto;
  }

  .header {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .header__name {
    margin-right: 20px;
  }

  .general {
    padding: 0;
  }

  .general__choose__list {
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .general__choose__item {
    width: 100%;
  }

  .footer {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }

  .d-md-block {
    display: block;
  }

  .d-md-none {
    display: none;
  }

  .content__inner {
    padding-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .number_radio {
    display: inline;
  }
  .footer__nav {
    flex-wrap: wrap;
  }
  .footer {
    padding: 20px 0;
  }
}
@media (max-width: 768px) {
  .test__item {
    display: block;
  }
  .result-btn-container {
    display: block !important;
  }
  .result-btn-container a {
    margin: 0 !important;
  }
  .result-btn-container .btn {
    width: 100%;
  }
  .number_radio {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 1 !important;
    flex: 0 1 !important;
  }

  .test__radio {
    -webkit-box-flex: 100%;
    -ms-flex: 100% 0 1;
    flex: 100% 0 1;
    max-width: 100%;
  }
  .test__img {
    max-width: 100%;
  }
  .test__img:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .header > * {
    width: 100%;
    margin: 10px 0;
  }

  .result {
    min-width: 280px;
    width: 100%;
  }
}
.table__tests {
  overflow: auto;
}
@media (max-width: 400px) {
  .burger__wrapper,
  .burger__wrapper .time {
    font-size: 25px;
  }
  .register__btn-demo {
    font-size: 15px;
  }
  .register__footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }
  .thanks-msg {
    &__icon {
      margin-bottom: 10px;
    }
    &__title {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    &__descr {
      font-size: 15px;
    }
  }
}

.test__btn:disabled {
  background: rgba(128, 128, 128, 0.8);
}

.blob {
  // background: black;
  // border-radius: 50%;
  margin: 10px;
  // height: 20px;
  // width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
