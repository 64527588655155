.error,.success
    padding: 10px 15px
    min-width: 30%
    position: absolute
    top: -38px
    left: 50%
    right: 50%
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: bold
.success
    background-color: #DFF2BF
    color: #4F8A10

.error
    background-color: #FFD2D2
    color: #D8000C
