#customers 
    min-width: 100%
    width: 100%
    @media (max-width: 768px)
        width: 820px
         
#customers td, #customers th
    border: 1px solid #ddd
    padding: 8px
    text-align: center
    
#customers
    tr
        &:nth-child(even)
            background-color: #f2f2f2

#customers
    tr
        &:hover
            background-color: #ddd

#customers
    th
        padding-top: 12px
        padding-bottom: 12px
        text-align: left
        background-color: #2291e3
        color: white
        text-align: center
        